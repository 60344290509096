import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AppConfigContext } from '../contexts/AppConfigContext';
import { useDimensions } from '../hooks/useDimensions';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { getConfig } from '../utils/api';
import { AppConfigContextType } from '../contexts/AppConfigContext.types';

import './App.css';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Project } from './Project/Project';
import { HomePage } from './HomePage/HomePage';
import { Preloader } from './Loader/Preloader';
import { PreloadImages } from './Loader/PreloadImages';

function App() {
  useScrollToTop();
  const [config, setConfig] = useState<AppConfigContextType | null>(null);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadedImages, setLoadedImages] = useState(false);

  const isLoading = loadingConfig || !loadedImages;

  const images = useMemo(
    () => config?.projects.map((p) => p.heroImage),
    [config],
  );

  useEffect(() => {
    if (!config) {
      getConfig()
        .then((data) => setConfig(data))
        .catch((err) => console.log('err', err));
    }
    setTimeout(() => setLoadingConfig(false), 800);
  }, []);

  const headerRef = useRef<HTMLElement | null>(null);
  const { height: headerHeight } = useDimensions(headerRef, config);

  const paddingCalc = useMemo(
    () => ({ paddingTop: `${headerHeight}px` }),
    [headerHeight],
  );

  return (
    <AppConfigContext.Provider value={config}>
      <div className="template">
        <PreloadImages images={images} onLoaded={setLoadedImages} />
        <div className="max-w-7xl w-full min-h-screen relative">
          <Header ref={headerRef} className={isLoading ? 'invisible' : ''} />
          {!isLoading ? (
            <>
              <section style={paddingCalc} className="min-h-screen">
                <Routes>
                  <Route
                    path="/"
                    element={<HomePage headerHeight={headerHeight} />}
                  />
                  <Route path="/project/:projectId" element={<Project />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </section>
              <Footer />
            </>
          ) : (
            <>
              <Preloader />
            </>
          )}
        </div>
      </div>
    </AppConfigContext.Provider>
  );
}

export default App;
