import React, { ForwardedRef, forwardRef } from 'react';
import clsx from 'clsx';

import { fixPhoneNumber } from '../../utils/common';
import { Logo } from '../Logo/Logo';
import { MessengerIcon } from '../Icons/MessengerIcon';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { DEFAULT_CONFIG } from '../../utils/constants';

import styles from './Header.module.css';

// eslint-disable-next-line react/display-name
export const Header = forwardRef(
  (
    { className }: { className?: string },
    ref: ForwardedRef<HTMLElement> | null,
  ) => {
    const config = useAppConfigContext() || DEFAULT_CONFIG;

    return (
      <header className={clsx(styles.container, className)} ref={ref}>
        <div className={styles.content}>
          <Logo size="xl" />
          <div className={styles.contactsBox}>
            <a
              className={clsx(styles.tel, 'glow')}
              href={`tel:${fixPhoneNumber(config.phoneNumber)}`}
            >
              {config.phoneNumber}
            </a>
            <MessengerIcon type="telegram" className="text-sm xs:text-lg" />
            <MessengerIcon type="whatsapp" className="text-sm xs:text-lg" />
          </div>
        </div>
      </header>
    );
  },
);
