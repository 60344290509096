export const BASE_URL = 'https://storage.yandexcloud.net/chernikovdesign-data';

export const DEFAULT_CONFIG = {
  logo: 'Chernikov Design',
  phoneNumber: '+7 (911) 242 86 25',
  email: 'chernikovdesign@mail.ru',
  telegram: 'https://t.me/Chernikov_design',
  whatsapp: 'https://wa.me/79112428625',
  instagram: 'https://www.instagram.com/chernikov_design/',
  behance: 'https://www.behance.net/pavelchernikovdesign',
  youtube: 'https://www.youtube.com/channel/UCnARQcLGP9lve6-DO7GqwGQ',
  vk: 'https://vk.com/chernikov_design',
};
