import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { DEFAULT_CONFIG } from '../../utils/constants';

import styles from './Logo.module.css';

export const Logo = ({
  className,
  size,
}: {
  className?: string;
  size?: 'sm' | 'xl';
}) => {
  const navigate = useNavigate();
  const config = useAppConfigContext() || DEFAULT_CONFIG;

  const classSize = size === 'xl' ? styles.textLarge : styles.textSmall;

  return (
    <button
      className={clsx(styles.logo, className)}
      onClick={() => navigate('/')}
      type="button"
    >
      <p className={classSize}>
        <span className="tracking-wider">{config.logo}</span>
      </p>
    </button>
  );
};
