import React from 'react';
import { AboutMe } from '../AboutMe/AboutMe';
import { Prices } from '../Prices/Prices';
import { ProjectList } from '../ProjectList/ProjectList';
import { Services } from '../Services/Services';

export const HomePage = ({ headerHeight }: { headerHeight: number }) => {
  return (
    <>
      <ProjectList headerHeight={headerHeight} />
      <AboutMe />
      <Services />
      <Prices />
    </>
  );
};
